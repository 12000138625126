<template>
	<Dialog :dialog="dialog" v-if="dialog" :dialog-width="724" @close="$emit('close')">
		<template #title>
			<div style="width: 100%" class="d-flex justify-space-between">
				<div class="d-flex">
					<div>Create Backlink Library</div>
				</div>
			</div>
		</template>
		<template #body>
			<v-form
				ref="extendCheckoutForm"
				v-model.trim="formValid"
				lazy-validation
				@submit.stop.prevent="updateOrCreateBacklink"
			>
				<v-row>
					<v-col md="2"><label for="" class="required"> Country </label></v-col>
					<v-col md="10">
						<v-select
							outlined
							:items.sync="countryList"
							id="country"
							style="margin-top: 0px !important"
							hide-details
							clearable
							placeholder="Country"
							v-model="newSite.country"
							:rules="[vrules.required(newSite.country, 'Country')]"
							:menu-props="{ offsetY: true }"
							:class="{
								required: !newSite.country,
							}"
						>
							<template #item="{ item }">
								<div class="d-flex py-1 align-center justify-center">
									<div class="mr-2">
										<v-icon
											style="width: 20px; height: 14px"
											v-if="item.flag == 'Global'"
											color="blue darken-3"
											>mdi-earth</v-icon
										>
										<v-icon v-else :class="item.flag.toLowerCase()" class="vti__flag" />
									</div>
									<div>
										<span class="fw-500">
											{{ item.text }}
										</span>
									</div>
								</div>
							</template>
						</v-select>
					</v-col>
					<v-col md="2"> <label for="" class="required">Activity</label> </v-col>

					<v-col md="10" class="">
						<!-- style="margin-top: 0px !important; min-width: max-content" -->
						<SelectInput
							:items.sync="activityList"
							id="country"
							hideDetails
							name="text"
							clearable
							itemValue="value"
							itemText="title"
							placeholder="Activity"
							style="margin-top: 0px !important"
							v-model="newSite.activity"
							:rules="[vrules.required(newSite.activity, 'Activity Type')]"
							:menu-props="{ offsetY: true }"
							:customClass="{
								required: !newSite.activity,
							}"
							no-style-item="title"
						></SelectInput>
						<!-- <ManageAutocomplete title="Activity" typeId="54" v-on:update="getSettings">
					</ManageAutocomplete> -->
					</v-col>

					<v-col md="2"><label class="required"> Category </label></v-col>

					<v-col md="10" class="">
						<SelectInput
							:items.sync="categoryList"
							id="category"
							style="margin-top: 0px !important"
							hide-details
							clearable
							name="text"
							itemValue="value"
							itemText="title"
							placeholder="Category"
							v-model="newSite.category"
							:rules="[vrules.required(newSite.category, 'Category Type')]"
							:menu-props="{ offsetY: true }"
							:customClass="{
								required: !newSite.category,
							}"
							no-style-item="title"
						></SelectInput>
						<!-- <ManageAutocomplete title="Category" typeId="54" v-on:update="getSettings">
					</ManageAutocomplete> -->
					</v-col>
					<!-- <v-col md="1" class="d-flex align-center" style="margin-top: 4px !important;"> -->
					<!-- <v-tooltip right>
						<template v-slot:activator="{ on, attrs }"> -->
					<!-- v-on="on" v-bind="attrs" -->
					<!-- <ManageAutocomplete title="Category" typeId="54" v-on:update="getSettings"> -->
					<!-- </ManageAutocomplete> -->
					<!-- </template>
						<span>Add new category</span>
					</v-tooltip> -->
					<!-- </v-col> -->

					<v-col md="2"> <label for="" class="required">Domain</label> </v-col>
					<v-col md="10">
						<TextInput
							hide-details
							style="margin-top: 0px !important"
							:disabled="pageLoading"
							:loading="pageLoading"
							id="domain"
							placeholder="Domain"
							v-model="newSite.domain"
							:rules="rules.domain"
							:customClass="{
								required: !newSite.domain,
							}"
						></TextInput>
						<!-- :rules="[vrules.required(newSite.domain, 'Domain')]" -->
					</v-col>
					<v-col md="2"> <label for="" class="required">DA</label> </v-col>
					<v-col md="10">
						<NumberInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							style="margin-top: 0px !important"
							id="domain-authority"
							placeholder="Domain Authority"
							v-model="newSite.da"
							:rules="rules.da"
							:customClass="{
								required: !newSite.da,
							}"
						></NumberInput>
						<!-- :rules="[vrules.required(newSite.domain, 'Domain')]" -->
						<!-- :rules="vrules.required(newSite.da, 'Domain Authority')" -->
					</v-col>
					<v-col md="2"> <label for="" class="required">SS</label> </v-col>
					<v-col md="10">
						<NumberInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							style="margin-top: 0px !important"
							id="spam-score"
							placeholder="Spam Score"
							v-model="newSite.ss"
							:rules="rules.ss"
							:customClass="{
								required: !newSite.ss,
							}"
						></NumberInput>
						<!-- :rules="vrules.required(newSite.ss, 'Spam Score')" -->
						<!-- :rules="[vrules.required(newSite.domain, 'Domain')]" -->
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				color="blue darken-4"
				class="white--text"
				depressed
				tile
				:disabled="!formValid || pageLoading"
				v-on:click="updateOrCreateBacklink"
			>
				Save
			</v-btn>
			<v-btn @click="$emit('close', false)" depressed tile :disabled="pageLoading"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
// <style src="@/assets/sass/sprite.scss" lang="scss"></style>;
import TextInput from "@/view/components/TextInput";
import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";
import Dialog from "@/view/components/Dialog";
import { POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";

export default {
	components: {
		Dialog,
		TextInput,
		SelectInput,
		NumberInput,
		// ManageAutocomplete,
	},
	props: {
		dialog: {
			type: Boolean,
		},
		categoryList: {
			type: Array,
			default: () => [],
		},
		activityList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			formValid: false,
			newSite: {
				country: null,
				activity: null,
				category: null,
				domain: null,
				da: null,
				ss: null,
			},
			pageLoading: false,
			// categoryList: [
			// { value: "wedding_decoration", text: "Wedding Decoration" },
			// { value: "furniture", text: "Furniture" },
			// { value: "office_furniture", text: "Office Furniture" },
			// { value: "logistics", text: "Logistics" },
			// { value: "pest_control", text: "Pest Control" },
			// { value: "cleaning", text: "Cleaning" },
			// { value: "industrial_equipment", text: "Industrial Equipment" },
			// { value: "massage", text: "Massage" },
			// { value: "game", text: "Game" },
			// { value: "computer_repair", text: "Computer Repair" },
			// { value: "trading", text: "Trading" },
			// { value: "lawyer", text: "Lawyer" },
			// { value: "machines_&_tools", text: "Machines & Tools" },
			// { value: "garden_&_environment", text: "Garden & Environment" },
			// { value: "door", text: "Door" },
			// { value: "marine_accessories_&_valve", text: "Marine Accessories & valve" },
			// { value: "water_filter_&_dispenser", text: "Water filter & Dispenser" },
			// { value: "electrician_&_maintenance", text: "Electrician & maintenance" },
			// { value: "food", text: "Food" },
			// { value: "beauty_products_&_services", text: "Beauty products & services" },
			// { value: "plumbing_services", text: "Plumbing services" },
			// { value: "aircon", text: "Aircon" },
			// { value: "software", text: "Software services" },
			// { value: "photography", text: "Photography" },
			// { value: "florist", text: "Florist" },
			// { value: "flooring", text: "Flooring" },
			// { value: "renovation_&_designer", text: "Renovation & Designer" },
			// { value: "security", text: "Security" },
			// { value: "health_&_medical", text: "Health & Medical" },
			// { value: "corporate_gifts", text: "Corporate Gifts" },
			// { value: "funeral", text: "Funeral" },
			// { value: "education_&_training", text: "Education & Training" },
			// { value: "marketing_services", text: "Marketing Services" },
			// { value: "automobile", text: "Automobile" },
			// { value: "garments", text: "Garments" },
			// { value: "event_management", text: "Event Management" },
			// { value: "accounting_&_financial_services", text: "Accounting & Financial Services" },
			// { value: "digital_lock", text: "Digital Lock" },
			// { value: "agriculture", text: "Agriculture" },
			// { value: "homeware_products", text: "Homeware products" },
			// { value: "real_estate", text: "Real estate" },
			// { value: "construction", text: "Construction" },
			// { value: "consultancy_recruitment", text: "Consultancy/ Recruitment" },
			// { value: "tour_travel", text: "Tour/Travel" },
			// { value: "charity_organisation", text: "Charity Organisation" },
			// { value: "miscellaneous", text: "Miscellaneous" },
			// { value: "other", text: "Other" },
			// ],
			// activityList: [],
			countryList: [
				{
					text: "Singapore",
					flag: "SG",
					value: "Singapore",
				},
				{
					text: "Malaysia",
					flag: "MY",
					value: "Malaysia",
				},
				{
					text: "United State",
					flag: "US",
					value: "United State",
				},
				{
					text: "Australia",
					flag: "au",
					value: "Australia",
				},
				{
					text: "Nigeria",
					flag: "NG",
					value: "Nigeria",
				},
				{
					text: "Vietnam",
					flag: "VN",
					value: "Vietnam ",
				},
				{
					text: "Thailand",
					flag: "TH",
					value: "Thailand",
				},
				{
					text: "Global",
					flag: "Global",
					value: "Global",
				},
			],
			backlinkId: null,
			rules: {
				domain: [
					(v) => !!v || "Domain is required",
					(v) => /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(v) || "Invalid domain format",
				],
				da: [
					(v) => !!v || "Domain Authority is required",
					(v) => (v > 0 && v <= 100) || "Must be less than 100",
				],
				ss: [
					(v) => !!v || "Spam Score is required",
					(v) => (v > 0 && v <= 100) || "Must be less than 100",
				],
			},
		};
	},
	computed: {
		mod_activityList() {
			return this.activityList.filter((v) => !v.value.includes("syndication"));
		},
	},
	mounted() {},
	methods: {
		updateOrCreateBacklink() {
			const formErrors = this.validateForm(this.$refs["extendCheckoutForm"]);
			this.$refs["extendCheckoutForm"].validate();
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}
			if (!this.$refs["extendCheckoutForm"].validate()) {
				return false;
			}
			this.pageLoading = true;
			let formData = new FormData();
			formData.append("country", this.newSite.country ? this.newSite.country : null);
			formData.append("activity", this.newSite.activity ? this.newSite.activity : null);
			formData.append("category", this.newSite.category ? this.newSite.category : null);
			formData.append("domain", this.newSite.domain ? this.newSite.domain : null);
			formData.append("domain_authority", this.newSite.da ? this.newSite.da : "crm");
			formData.append("spam_score", this.newSite.ss ? this.newSite.ss : 1);
			let requestTYPE = POST;
			let requestURL = "create-backlink";
			if (this.backlinkId) {
				requestURL = `create-backlink/${this.backlinkId}`;
			}
			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					if (this.leadId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Backlink updated successfully." },
						]);
					} else {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Backlink created successfully." },
						]);
						this.$refs.extendCheckoutForm.reset();
					}
					this.$emit("success");
					this.$emit("refresh", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
};
</script>

<style></style>
