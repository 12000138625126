<template>
	<v-sheet>
		<div class="customer-template-setting">
			<v-layout class="my-2">
				<v-flex>
					<div class="fw-600 sub-heading primary--text">Backlink Library</div>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex class="d-flex justify-content-end mr-1">
					<v-btn
						v-if="
							selectedRows &&
							Array.isArray(selectedRows) &&
							selectedRows.length > 0 &&
							getPermission('backlink_library_settings:delete')
						"
						depressed
						:disabled="pageLoading"
						@click.stop.prevent="showDeleteDialog"
						tile
						color="red lighten-1"
						class="text-white mr-2"
					>
						<v-icon left> mdi-delete </v-icon>
						Bulk Delete
					</v-btn>
					<v-btn class="" dense depressed tile @click="goBack">
						<v-icon class="mr-1">mdi-arrow-left</v-icon>
						Back
					</v-btn>
				</v-flex>
			</v-layout>

			<v-row class="mb-2">
				<v-col md="2" class="pr-1 py-0">
					<TextInput
						hide-details
						:disabled="pageLoading"
						:loading="pageLoading"
						id="search"
						placeholder="Search"
						v-model="backLinkSearch.search"
						:menu-props="{ bottom: true, offsetY: true }"
						@click:clear="defaultFilter = {}"
						@input="searchLibrary"
						hideTopMargin
						clearable
						customClass="filterSelect"
					></TextInput>
				</v-col>
				<v-col md="2" class="px-1 py-0">
					<v-autocomplete
						:items="users"
						:menu-props="{ bottom: true, offsetY: true }"
						outlined
						:disabled="pageLoading"
						v-model="backLinkSearch.added_by"
						clearable
						v-on:click:clear="defaultFilter = {}"
						style="max-width: 250px !important"
						hide-details
						item-text="display_name"
						item-value="id"
						placeholder="Select SEO Users"
						@change="topBarFilter('sales_person')"
						class="filterSelect"
					>
						<template #selection="data">
							<v-chip v-bind="data.attrs" x-small :input-value="data.selected" @click="data.select">
								<v-avatar left x-small>
									<v-avatar left v-if="data.item.profile_img">
										<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-avatar>
								</v-avatar>
								<span class="fw-600 text-uppercase">{{ data.item.display_name }}</span>
							</v-chip>
						</template>
						<template #item="{ item }">
							<div class="d-flex align-center py-1">
								<v-avatar class="mr-2" size="40">
									<v-img v-if="item.profile_img" :src="item.profile_img" />
									<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
								</v-avatar>
								<div>
									<div class="fw-600 text-uppercase">{{ item.display_name }}</div>
									<div class="text-muted">{{ item.email }}</div>
								</div>
							</div>
						</template>
					</v-autocomplete>
				</v-col>
				<v-col md="2" class="px-1 py-0">
					<DateRangePicker
						:inlineStyle="{ minWidth: '14rem' }"
						hide-details
						hide-top-margin
						clearable
						v-model="backLinkSearch.filter.daterange"
						:menu-props="{ bottom: true, offsetY: true }"
						@click:clear="(backLinkSearch.filter.daterange = null), topBarFilter('daterange')"
						@change="
							() =>
								(backLinkSearch.filter.daterange == null || backLinkSearch.filter.daterange.length > 1) &&
								topBarFilter('daterange')
						"
					></DateRangePicker>
				</v-col>
				<v-col md="2" class="px-1 py-0">
					<SelectInput
						:items.sync="categoryList"
						id="category"
						style="margin-top: 0px !important; min-width: 15rem !important"
						hide-details
						name="text"
						itemValue="value"
						itemText="title"
						placeholder="Category"
						clearable
						customClass="filterSelect"
						v-model="backLinkSearch.category"
						:menu-props="{ bottom: true, offsetY: true }"
						@click:clear="(backLinkSearch.category = null), topBarFilter('category')"
						@change="topBarFilter('category')"
						no-style-item="title"
					></SelectInput>
				</v-col>
				<v-col md="2" class="px-1 py-0">
					<v-select
						outlined
						:items.sync="countryList"
						id="country"
						style="margin-top: 0px !important"
						hide-details
						clearable
						placeholder="Country"
						v-model="backLinkSearch.country"
						:menu-props="{ bottom: true, offsetY: true }"
						@click:clear="defaultFilter = {}"
						@change="topBarFilter('country')"
						class="filterSelect"
					>
						<template #item="{ item }">
							<div class="d-flex py-1 align-center justify-center">
								<div class="mr-3" :class="[{ 'mr-4': item.flag == 'all' }]">
									<v-icon style="width: 15px; height: 15px" v-if="item.flag == 'all'" color="blue darken-3"
										>mdi-earth</v-icon
									>
									<v-icon v-else :class="item.flag.toLowerCase()" class="vti__flag" />
								</div>
								<div class="fw-500">
									{{ item.text }}
								</div>
							</div>
						</template>
					</v-select>
				</v-col>
				<v-col md="2" class="pl-1 py-0">
					<SelectInput
						:items.sync="activityList"
						id="activity"
						style="margin-top: 0px !important"
						hide-details
						clearable
						class="mr-1 filterSelect"
						name="text"
						value="value"
						itemText="title"
						placeholder="Activity"
						v-model="backLinkSearch.activity"
						:menu-props="{ bottom: true, offsetY: true }"
						@click:clear="(defaultFilter = {}), topBarFilter('activity')"
						@change="topBarFilter('activity')"
						no-style-item="title"
					></SelectInput>
				</v-col>
			</v-row>

			<div class="border">
				<v-layout class="py-2 px-4 blue lighten-5">
					<v-flex class="">
						<div class="fw-600 sub-heading primary--text text-capitalize">Backlink Library</div>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex
						v-if="
							getPermission('backlink_library_settings:create') &&
							AllowAnyOforGetRoleNType([
								'master',
								'admin',
								'manager',
								'super_admin',
								'team_lead',
								'ateam_lead',
							])
						"
						class="d-flex justify-content-end"
					>
						<v-btn
							class="mx-4 white--text"
							depressed
							dense
							tile
							color="blue darken-4"
							@click="createBacklibrary"
						>
							Create
						</v-btn>
						<v-btn
							class="white--text"
							depressed
							dense
							tile
							color="blue darken-4"
							@click="importDialog = true"
						>
							Import
						</v-btn>
					</v-flex>
				</v-layout>

				<v-simple-table class="bt-table" height="684">
					<thead>
						<tr style="background-color: white !important">
							<th
								v-if="
									getPermission('backlink_library_settings:delete') &&
									AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
								"
								style="background: #fff !important"
								class="p-2 custome-table"
								width="50px"
							>
								<v-checkbox
									:ripple="false"
									v-model="checkAllLibrary"
									color="#0d47a1"
									hide-details
									class="p-0 m-0"
								></v-checkbox>
							</th>
							<th
								v-if="
									getPermission('backlink_library_settings:delete') &&
									AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
								"
								style="background: #fff !important"
								width="50px"
								class="p-2 custome-table"
							>
								Action
							</th>
							<th style="background: #fff !important" class="p-2">Domain</th>
							<th style="background: #fff !important" class="p-2 custome-table">Category</th>
							<th style="background: #fff !important" class="p-2 custome-table">Activity</th>
							<th style="background: #fff !important" class="p-2 custome-table">
								<v-tooltip top content-class="custom-top-tooltip">
									<template #activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs">DA</div>
									</template>
									<span>Domain Autority</span>
								</v-tooltip>
							</th>
							<th style="background: #fff !important" class="p-2 custome-table">
								<v-tooltip top content-class="custom-top-tooltip">
									<template #activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs">SS</div>
									</template>
									<span>Spam Score</span>
								</v-tooltip>
							</th>
							<th style="background: #fff !important" class="p-2 custome-table">Country</th>
							<th style="background: #fff !important; width: 200px" class="p-2 custome-table">Added By</th>
						</tr>
					</thead>
					<tbody v-if="Array.isArray(backLink_library) && backLink_library.length">
						<tr v-for="(row, index) in backLink_library" :key="`row_index-${index}`">
							<td
								v-if="
									getPermission('backlink_library_settings:delete') &&
									AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
								"
								class="p-2 border-top-light-grey custome-table-td text-center"
							>
								<v-checkbox
									:key="+new Date()"
									color="#0d47a1"
									:ripple="false"
									hide-details
									:value="row.id"
									v-model="selectedRows"
									class="p-0 m-0 text-center"
								></v-checkbox>
							</td>
							<td
								v-if="
									getPermission('backlink_library_settings:delete') &&
									AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
								"
								class="border-top-light-grey custome-table-td text-center"
							>
								<v-tooltip z-index="9" top content-class="custom-top-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											fab
											dark
											x-small
											color="red"
											v-bind="attrs"
											v-on="on"
											v-on:click.stop.prevent="showDeleteDialog(row.id)"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</template>
									<span>Delete Backlink Library</span>
								</v-tooltip>
							</td>
							<td style="max-width: 250px" class="p-2 border-top-light-grey">
								<template v-if="row.domain != 'null' && row.domain != null">
									<v-tooltip top>
										<template #activator="{ on, attrs }">
											<div @click="clickAndCopy(row.domain)" v-on="on" v-bind="attrs" class="text-truncate">
												{{ row.domain }}
											</div>
										</template>
										<span>{{ row.domain }}</span>
									</v-tooltip>
								</template>
								<em v-else>no domain</em>
							</td>

							<td class="p-2 border-top-light-grey custome-table-td">
								<template v-if="row.category != 'null' && row.category != null">
									<v-chip label outlined color="green">{{
										categoryList.find((v) => v.value == row.category)?.title
									}}</v-chip>
								</template>
								<em v-else>no category</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<template v-if="row.activity != 'null' && row.activity != null">
									<div>{{ activityList.find((v) => v.value == row.activity)?.title }}</div>
								</template>
								<em v-else>no activity</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<template v-if="row.domain_authority">
									<v-chip small outlined label color="#2e2e2e">
										<span class="fw-600">
											{{ row.domain_authority }}
										</span>
									</v-chip>
								</template>
								<em v-else>no Domain Authority</em>
								<!-- <ShowValue :object="row" object-key="activity" label="activity"></ShowValue> -->
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<template v-if="row.spam_score">
									<v-chip small outlined label color="#2e2e2e">
										<span class="fw-600">
											{{ row.spam_score }}
										</span>
									</v-chip>
								</template>
								<em v-else>no Spam Score</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<template v-if="row.country != 'null' && row.country != null">
									<v-icon
										style="width: 15px; height: 15px"
										v-if="countryFlag(row.country)?.flag == 'all'"
										class="mr-2"
										color="blue darken-3"
										>mdi-earth
									</v-icon>
									<v-icon v-else :class="countryFlag(row.country)?.flag" class="vti__flag mr-2" />
									<span class="fw-500">{{ row.country }}</span>
								</template>
								<em v-else>no country</em>
								<!-- <ShowValue :object="row" object-key="country" label="country"></ShowValue> -->
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<template v-if="row.added_by.display_name != 'null' && row.added_by.display_name != null">
									<div class="text-capitalize">{{ row.added_by.display_name }}</div>
								</template>
								<em v-else>no display name</em>
								<!-- <ShowValue :object="row" object-key="added_by" label="added_by"></ShowValue> -->
								<div>
									<v-chip outlined label color="green" class="mt-1" x-small>
										{{ row.added_at }}
									</v-chip>
								</div>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="4">
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no backlink at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>

				<CreateBacklLink
					v-if="createBackLinkDialog"
					@success="getBacklinkLibrary"
					@close="createBackLinkDialog = false"
					:dialog="createBackLinkDialog"
					:categoryList="categoryList"
					:activityList="activityList"
				/>
			</div>
		</div>

		<v-row class="listing-footer py-2" v-if="backLink_library.length">
			<v-col class="my-auto fw-500">
				{{ showing_string }}
			</v-col>
			<v-col class="my-auto">
				<v-pagination v-model="currentPage" :length="totalPages" @input="getBacklinkLibrary">
				</v-pagination
			></v-col>
		</v-row>

		<Dialog :dialog="deleteDialog" :dialog-width="600" @close="closeDeleteDialog">
			<template v-slot:title> Delete Backlink Library</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<template v-if="selectedRows && Array.isArray(selectedRows) && selectedRows.length > 0">
							<p class="btx-p m-0">
								Bulk Deleting Backlink library is irreversible, Are you sure about deleting it?
							</p>
						</template>
						<p v-else class="btx-p m-0">
							Deleting Backlink library is irreversible, Are you sure about deleting it?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteLoading"
					:disabled="deleteLoading"
					depressed
					color="red lighten-1"
					tile
					@click="deleteLibrary"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteLoading" v-on:click="closeDeleteDialog">
					No, Close
				</v-btn>
			</template>
		</Dialog>

		<ImportDialog
			v-if="importDialog"
			endpoint="backlink/import"
			title="Backlink Library"
			:import-dialog.sync="importDialog"
			@close="importDialog = false"
			@refress="getBacklinkLibrary(1)"
			sample-file="/media/sample-files/Sample Backlink Library.csv"
		></ImportDialog>
	</v-sheet>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import {
	GET,
	QUERY,
	DELETE,
	// PATCH
} from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
// import ShowValue from "@/view/components/ShowValue";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import SelectInput from "@/view/components/SelectInput";
import DateRangePicker from "@/view/components/DateRangePickerV2";
import CreateBacklLink from "@/view/components/CreateBacklink";
import { SET_FILTER } from "@/core/services/store/listing.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import ImportDialog from "@/view/components/ImportDialogSEOTabs.vue";
import HeightMixin from "@/core/mixins/height.mixin";
/* import { EventBus } from "@/core/event-bus/event.bus"; */

export default {
	name: "BacklinkLibarary",
	mixins: [MainMixin, HeightMixin],
	components: {
		Dialog,
		SelectInput,
		TextInput,
		CreateBacklLink,
		DateRangePicker,
		ImportDialog,
	},
	data() {
		return {
			skipBottomHeight: 74,
			zoom: {
				client_id: null,
				client_secret: null,
			},
			createBackLinkDialog: false,
			newSite: {
				domain: null,
				category: null,
			},
			categoryList: [],
			activityList: [],

			backLinkSearch: {
				search: "",
				added_by: "",
				category: "",
				activity: "",
				country: "",
				filter: {
					daterange: [],
				},
			},
			users: [],
			backLink_library: [],
			pageLoading: false,
			countryList: [
				{
					text: "Singapore",
					flag: "SG",
					value: "singapore",
				},
				{
					text: "Malaysia",
					flag: "MY",
					value: "malaysia",
				},
				{
					text: "United State",
					flag: "US",
					value: "united_state",
				},
				{
					text: "Australia",
					flag: "au",
					value: "australia",
				},
				{
					text: "Nigeria",
					flag: "NG",
					value: "nigeria",
				},
				{
					text: "Vietnam",
					flag: "VN",
					value: "vietnam ",
				},
				{
					text: "Thailand",
					flag: "TH",
					value: "thailand",
				},
				{
					text: "Global",
					flag: "all",
					value: "all",
				},
			],
			currentPage: 1,
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			showingFrom: 0,
			showingTo: 0,
			deleteDialog: false,
			alldeletelibrary: false,
			deleteLoading: false,
			libraryIdToDelete: null,
			selectedRows: [],
			debounceTimer: null,
			importDialog: false,
		};
	},
	watch: {
		"$route.query": {
			handler(query) {
				if (query && query.tab == "backlink_library") {
					this.getBacklinkActivity();
					this.getBacklinkCategory();
					this.backLinkSearch = {
						search: "",
						added_by: "",
						category: "",
						activity: "",
						country: "",
						filter: {
							daterange: [],
						},
					};
					this.getBacklinkLibrary();
				}
			},
		},
	},
	mounted() {
		this.getBacklinkLibrary();
		this.getSEOUsers();
		this.getBacklinkActivity();
		this.getBacklinkCategory();
		/* Retains transition b/w components */
		/* EventBus.$on("refresh:fields", (evt) => {
			if (evt) {
				this.backLinkSearch = {
					search: "",
					added_by: "",
					category: "",
					activity: "",
					country: "",
					filter: {
						daterange: [],
					},
				};
				this.getBacklinkLibrary();
			}
		}); */
	},
	/* beforeDestroy() {
		EventBus.$off("refresh:fields");
	}, */
	methods: {
		searchLibrary() {
			clearTimeout(this.debounceTimer);
			this.debounceTimer = setTimeout(() => {
				// if (this.backLinkSearch.search == null || ) {
				this.topBarFilter("search");
				// }
			}, 600);
		},
		countryFlag(country) {
			const _country = this.countryList.find((ctry) => ctry.text == country);
			if (_country) {
				return { ..._country, flag: _country.flag.toLowerCase() };
			} else {
				return null;
			}
		},
		getBacklinkActivity() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "list-activity" })
				.then((data) => {
					if (data.data && Array.isArray(data.data)) {
						_this.activityList = data.data.map((row) => {
							return { title: row.activity, value: row.value, id: row.id };
						});
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getBacklinkCategory() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "list-category" })
				.then((data) => {
					if (data.data && Array.isArray(data.data)) {
						_this.categoryList = data.data.map((row) => {
							return { title: row.category, value: row.value, id: row.id };
						});
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		formatDate(dateString) {
			const date = parseISO(dateString);
			return formatDistanceToNow(date, { addSuffix: true });
		},
		getSEOUsers() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, { url: "project-setting" })
				.then((data) => {
					if (data && Object.keys(data).length && Array.isArray(data.users) && data.users.length) {
						_this.users = data.users.filter((user) => user.users_type == "seo");
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getBacklinkLibrary(curr_page = this.currentPage || 1) {
			const _this = this;
			_this.$store
				.dispatch(QUERY, {
					url: "list-backlink",
					data: {
						page: curr_page,
						search: this.backLinkSearch.search || null,
						added_by: this.backLinkSearch.added_by || null,
						category: this.backLinkSearch.category || null,
						activity: this.backLinkSearch.activity || null,
						country: this.backLinkSearch.country || null,
						daterange: this.backLinkSearch.filter.daterange || null,
					},
				})
				.then((data) => {
					this.backLink_library = data?.data || [];
					this.showing_string = data.showing_string;
					this.totalPages = Math.ceil(data.totalRows / data.per_page);
					this.currentPage = data.current_page;
					this.totalRows = data.totalRows;
					this.rowsOffset = data.offset;
					this.showingFrom = data.showingFrom;
					this.showingTo = data.showingTo;
					this.selectedRows = [];

					/* _this.activityList = data.data.map((row) => {
						return { title: row.activity, value: row.activity, id: row.id };
					});
					_this.categoryList = data.data.map((row) => {
						return { title: row.category, value: row.category, id: row.id };
					}); */
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		topBarFilter(source) {
			console.log({ source });
			let formate =
				this.backLinkSearch.filter.daterange && this.backLinkSearch.filter.daterange.length > 0
					? this.backLinkSearch.filter.daterange
					: this.$route.query.date;
			let filter = {
				search: this.backLinkSearch.search || null,
				country: this.backLinkSearch.country || null,
				category: this.backLinkSearch.category || null,
				activity: this.backLinkSearch.activity || null,
				per_page: null,
				current_page: null,
				daterange: formate,
				project_id: null,
				seo_id: null,
				added_by: this.backLinkSearch.added_by || null,
			};
			this.$store
				.dispatch(QUERY, {
					url: `list-backlink`,
					data: { ...filter },
				})
				.then((data) => {
					this.backLink_library = data.data;
					this.showing_string = data.showing_string;
					this.totalPages = Math.ceil(data.totalRows / data.per_page);
					this.currentPage = data.current_page;
					this.totalRows = data.totalRows;
					this.rowsOffset = data.offset;
					this.showingFrom = data.showingFrom;
					this.showingTo = data.showingTo;
					this.$store.commit(SET_FILTER, data.data.filter);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		goBack() {
			this.$router.go(-1);
		},
		createBacklibrary() {
			this.createBackLinkDialog = true;
		},
		showDeleteDialog(libraryId) {
			this.libraryIdToDelete = libraryId;
			this.deleteDialog = true;
		},
		closeDeleteDialog() {
			this.deleteDialog = false;
			this.selectedRows = [];
		},
		deleteLibrary() {
			this.deleteLoading = true;
			let url = "";
			if (this.selectedRows.length === 0) {
				if (!this.libraryIdToDelete) {
					return;
				}
				const libraryIds = [];
				libraryIds.push(this.libraryIdToDelete);
				url = `delete-backlink?ids=${JSON.stringify(libraryIds)}`;
			} else {
				const idArr = this.selectedRows.slice();
				const encodedIds = encodeURIComponent(JSON.stringify(idArr));
				url = `delete-backlink?ids=${encodedIds}`;
			}
			this.$store
				.dispatch(DELETE, { url })
				.then(() => {
					const index = this.backLink_library.findIndex((item) => item.id === this.libraryIdToDelete);
					if (index !== -1) {
						this.backLink_library.splice(index, 1);
					}
					this.selectedRows = [];
					this.getBacklinkLibrary();
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Backlink library deleted successfully.",
						},
					]);
				})

				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.deleteLoading = false;
					this.closeDeleteDialog();
				});
		},
		deleteAll() {
			this.alldeletelibrary = true;
		},
		all_deleteLibrary() {},
	},
	computed: {
		checkAllLibrary: {
			get() {
				return Array.isArray(this.selectedRows) &&
					this.selectedRows.length === this.backLink_library.length &&
					this.backLink_library.length > 0
					? true
					: false;
			},
			set(value) {
				if (value) {
					this.selectedRows = this.backLink_library.map((v) => v.id);
				} else {
					this.selectedRows = [];
				}
			},
		},
		// ...mapGetters(["currentPage", "totalPage", "showingString"]),
	},
};
</script>
